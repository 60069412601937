<template>
  <div>包装后OBA抽检</div>
</template>

<script>
export default {
  name: "CheckAfterPackAdd"
}
</script>

<style scoped>

</style>